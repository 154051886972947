import Button from "components/Button";
import NodePublicationPreviewTeaser from "components/Publication/NodePublicationPreviewTeaser";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import React from "react";

export default function PublicationHomeSection({ node }) {
  const { t } = useTranslation("home");

  return (
    <div className="relative bg-secondary-light pt-24 pb-28 lg:pt-32 lg:pb-44 lg:pr-16">
      <div
        className="absolute inset-y-0 left-1/2 right-auto lg:left-auto lg:right-0 w-[100vw] lg:w-[50vw] bg-secondary-light -translate-x-1/2 lg:translate-x-0"
        aria-hidden="true"
      />

      <div className="relative">
        <h2 className="sr-only">{t("publications.title")}</h2>

        <NodePublicationPreviewTeaser node={node} />

        <div className="mt-16 text-center lg:text-left">
          <Link href={t("common:publication.path")} passHref>
            <Button as="a" theme="secondary">
              {t("publications.more")}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
