import React from "react";
import Image from "next/image";
import formatFileSize from "lib/formatFileSize";
import { DownloadIcon, EyeIcon } from "@heroicons/react/outline";
import useTranslation from "next-translate/useTranslation";

export default function NodePublicationPreviewTeaser({ node }) {
  const file = node.field_file;
  const extension = file.filename.split(".").pop();
  const filesize = formatFileSize(file.filesize);
  const { t } = useTranslation("common");

  return (
    <article className="flex flex-col xl:flex-row items-center lg:items-start gap-8">
      <div className="order-1 xl:absolute left-1/3 xl:ml-8">
        <div className="text-important uppercase text-secondary text-sm font-important font-medium tracking-wider mb-5 before:content-['///'] before:mr-2">
          {t("home:publications.pre_title")}
        </div>

        <h3 className="text-xl xl:text-2xl font-important font-bold">
          {node.title}
        </h3>
      </div>
      <div className="order-3 flex-1 xl:mt-32 2xl:mt-28">
        <div className="flex-1 flex flex-col sm:flex-row items-center gap-4">
          <a
            href={`${process.env.NEXT_PUBLIC_DRUPAL_RUNTIME_BASE_URL}${file.uri.url}`}
            className="group inline-flex items-center py-4 px-8 rounded-full border text-sm font-semibold no-underline transition-colors border-white bg-white text-default hover:border-secondary hover:bg-secondary hover:text-white"
            title={`Ouvrir "${file.filename}" dans un nouvel onglet`}
            target="_blank"
            rel="noreferrer"
          >
            <EyeIcon className="h-4 w-4 mr-2 text-secondary group-hover:text-white transition-colors" />
            <span>{t("utils.files.see")}</span>
          </a>

          <a
            href={`${process.env.NEXT_PUBLIC_DRUPAL_RUNTIME_BASE_URL}${file.uri.url}`}
            className="group inline-flex items-center py-4 px-8 rounded-full border text-sm font-semibold no-underline transition-colors border-white bg-white text-default hover:border-secondary hover:bg-secondary hover:text-white"
            title={`Télécharger "${file.filename}" (${filesize})`}
            download
          >
            <DownloadIcon className="h-4 w-4 mr-2 text-secondary group-hover:text-white transition-colors" />
            <span>{t("utils.files.download")}</span>
          </a>
        </div>
      </div>
      <div className="order-2 flex-initial w-2/3 sm:w-1/2 xl:w-1/3">
        <a
          href={`${process.env.NEXT_PUBLIC_DRUPAL_RUNTIME_BASE_URL}${file.uri.url}`}
          className="group block"
          title={`Ouvrir "${file.filename}" dans un nouvel onglet`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="aspect-w-21 aspect-h-29.7">
            <Image
              src={`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}${node.field_image.uri.url}`}
              width={210}
              height={297}
              layout="fill"
              objectFit="cover"
              alt={node.field_image?.alt}
            />
          </div>
        </a>
      </div>
    </article>
  );
}
