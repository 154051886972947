import React, { forwardRef } from "react";
import classNames from "lib/classNames";

const Button = forwardRef(
  (
    {
      children,
      theme = "primary",
      as = "button",
      size = "default",
      inverse = false,
      ...props
    },
    ref
  ) => {
    const Tag = as;

    const themes = {
      primary: {
        default:
          "border-primary bg-primary text-white hover:border-primary-dark hover:bg-primary-dark",
        inverse:
          "border-primary bg-white text-primary hover:bg-primary hover:text-white",
      },
      secondary: {
        default:
          "border-secondary bg-secondary text-white hover:border-secondary-dark hover:bg-secondary-dark",
        inverse:
          "border-secondary bg-transparent text-secondary hover:bg-secondary hover:text-white",
      },
      tertiary: {
        default:
          "border-tertiary bg-tertiary text-white hover:border-tertiary-dark hover:bg-tertiary-dark",
        inverse:
          "border-tertiary bg-transparent text-tertiary hover:bg-tertiary hover:text-white",
      },
    };

    const sizes = {
      default: "py-4 px-8 lg:py-6 lg:px-14",
      small: "py-4 px-8",
    };

    return (
      <Tag
        ref={ref}
        className={classNames(
          "inline-block rounded-full border text-sm font-important font-medium uppercase no-underline tracking-wider transition",
          inverse ? themes[theme].inverse : themes[theme].default,
          sizes[size]
        )}
        {...props}
      >
        {children}
      </Tag>
    );
  }
);

Button.displayName = "Button";
export default Button;
